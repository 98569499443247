<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    model="memberMessages"
  />
</template>
<script>
import { memberMessages } from '@/config/descriptions/memberMessages';
import { memberMessagesTexts } from '@/config/pageTexts/memberMessages.json';

export default {
  name: 'MemberMessagesTable',
  props: {
    statuses: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'memberMessages/memberMessages',
          fields: 'memberMessages/fields',
          sortableFields: 'memberMessages/sortableFields',
          pages: 'memberMessages/pages',
          loading: 'memberMessages/loading',
          resultCount: 'memberMessages/resultCount',
          totalRecords: 'memberMessages/totalRecords',
          originalFields: 'memberMessages/originalFields',
          searchableAllFields: 'memberMessages/searchableAllFields',
          success: 'memberMessages/success',
          message: 'memberMessages/message',
        },
        actions: {
          fields: 'memberMessages/handleFields',
          delete: 'memberMessages/handleDeleteMemberMessages',
          reset: 'memberMessages/handleClearFilter',
          query: 'memberMessages/handleGetMemberMessagesByQuery',
          updateStatus: 'memberMessages/handleUpdateMemberMessagesStatus',
        },
      },
      settings: {
        describe: { ...memberMessages },
        page: { ...memberMessagesTexts },
        statuses: ['All', 'Draft', 'Active', 'Expired', 'Cancelled', 'Deleted'],
      },
      route: {
        create: 'CreateMemberMessage',
        edit: 'EditMemberMessage',
        preview: 'PreviewMemberMessage',
      },
    };
  },
  created() {
    if (this.statuses.length) {
      this.settings.statuses = this.statuses
    }
  },
};
</script>